import { Button, Modal, Image } from "components/anti"

import { AsyncApollo } from "lib/functions"
import { Q_PAGES } from "lib/graphql"
import { useGeneralStore } from "lib/store"
import { useEffect, useState } from "react"

import icTicket from "assets/img/dwp/ic_ticket.png"
import icBeer from "assets/img/dwp/ic_beer.png"
import icHotel from "assets/img/dwp/ic_hotel.png"
import icStage from "assets/img/dwp/ic_stage.png"
import icToken from "assets/img/dwp/ic_token.png"
import icTshirt from "assets/img/dwp/ic_tshirt.png"
import goldenTicket from "assets/img/dwp/golden-ticket-shining.png"
import bgCover from "assets/img/dwp/bg-dwp-xv-landing-cover.jpg"

export default function ModalTncDwp({}) {
  const {
    modalTncDwpId: id,
    modalTncDwp: modal,
    setModalTncDwp: setModal,
  } = useGeneralStore((state) => state)
  const [tnc, setTnc] = useState(<></>)
  async function fetchTnc() {
    let data = await AsyncApollo({
      query: Q_PAGES,
      variables: { lang: "en" },
    })
    if (data?.data) {
      const result = data?.data?.pages.find(
        (el) => el.title === "#DWP24 GOLDEN TICKET"
      )
      setTnc(result?.content)
    }
  }
  useEffect(() => {
    fetchTnc()
  }, [])
  return (
    <Modal
      id={id}
      isShowing={modal}
      hide={() => setModal(null)}
      className="modal-tnc-dwp modal-md"
      title="#DWP24 Golden Ticket"
    >
      <Image src={bgCover} className="bg-cover" alt="Cover" />
      <Image
        src={goldenTicket}
        className={`img-fluid img-golden-ticket`}
        alt="img-golden-ticket"
      />
      <div className="modal-content-header">
        <h2 className="title">#DWP24 Golden Ticket</h2>
        <p>
          Get 1 Raffle Point to win these prizes below for every IDR 300K worth
          of #DWP24 official merch. purchased before 30 Nov 2023.
        </p>
        <div className="gt-prizes">
          <ul>
            <li>
              <Image
                src={icTicket}
                className="icon-prize img-fluid"
                alt="Icon"
              />
              <div className="text-prize">
                <h6>#DWP24 VVIP 3-Day Pass</h6>
                <p>from #DWP24</p>
              </div>
            </li>
            <li>
              <Image
                src={icHotel}
                className="icon-prize img-fluid"
                alt="Icon"
              />
              <div className="text-prize">
                <h6>Bali Hotel Accommodation for 3 days at 4-Star Hotel</h6>
                <p>from RoomKey</p>
              </div>
            </li>
            <li>
              <Image
                src={icTshirt}
                className="icon-prize img-fluid"
                alt="Icon"
              />
              <div className="text-prize">
                <h6>Merchandise Signed by International #DWP24 Artist</h6>
                <p>from Monstore + #DWP24</p>
              </div>
            </li>
            <li>
              <Image
                src={icToken}
                className="icon-prize img-fluid"
                alt="Icon"
              />
              <div className="text-prize">
                <h6>IDR 10 Mio worth of token</h6>
                <p>from Fooma</p>
              </div>
            </li>
            <li>
              <Image src={icBeer} className="icon-prize img-fluid" alt="Icon" />
              <div className="text-prize">
                <h6>IDR 10 Mio worth of beer cans for 3 days</h6>
                <p>from Heineken</p>
              </div>
            </li>
            <li>
              <Image
                src={icStage}
                className="icon-prize img-fluid"
                alt="Icon"
              />
              <div className="text-prize">
                <h6>Exclusive stage view pass in front of Garuda Stage</h6>
                <p>from Heineken</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-footer">
        <div dangerouslySetInnerHTML={{ __html: tnc }}></div>
      </div>
      <Button className="w-100" onClick={() => setModal(null)}>
        OK
      </Button>
    </Modal>
  )
}
