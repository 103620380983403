import nookies from "nookies"
import axios from "axios"
import { M_REFRESH_TOKEN } from "lib/graphql"
import Cookies from "js-cookie"
import { initializeApollo } from "lib/apollo"

/**
 *
 * @param {*} ctx
 * @returns
 */
export const TokenValidation = async (ctx, isProduct) => {
  let accessToken
  let guestToken
  let refreshToken
  if (!isProduct) {
    if (!ctx) {
      accessToken = Cookies.get(process.env.ACCESS_TOKEN)
      guestToken = Cookies.get(process.env.GUEST_TOKEN)
    } else {
      accessToken = nookies.get(ctx)[`${process.env.ACCESS_TOKEN}`]
      guestToken = nookies.get(ctx)[`${process.env.GUEST_TOKEN}`]
    }
    if (accessToken) {
      return { token: accessToken, user_status: true }
    } else {
      return { token: guestToken, user_status: false }
    }
  } else {
    if (!ctx) {
      accessToken = Cookies.get(process.env.ACCESS_TOKEN)
      refreshToken = Cookies.get(process.env.REFRESH_TOKEN)
      guestToken = Cookies.get(process.env.GUEST_TOKEN)
    } else {
      accessToken = nookies.get(ctx)[`${process.env.ACCESS_TOKEN}`]
      refreshToken = nookies.get(ctx)[`${process.env.REFRESH_TOKEN}`]
      guestToken = nookies.get(ctx)[`${process.env.GUEST_TOKEN}`]
    }
    if (!accessToken) {
      if (!refreshToken && !guestToken) {
        //!GET NEW GUEST TOKEN
        try {
          let { data } = await axios({
            url: process.env.GET_TOKEN_URI,
            method: "POST",
            data: {
              grant_type: process.env.TOKEN_TYPE,
              client_id: process.env.PASSPORT_CLIENT_ID,
              client_secret: process.env.PASSPORT_CLIENT_SECRET,
            },
          })

          nookies.set(ctx, `${process.env.GUEST_TOKEN}`, data.access_token, {
            maxAge: 11 * 24 * 60 * 60,
          })

          return { token: data.access_token, user_status: false }
        } catch (error) {
          return { user_status: false }
        }
      } else if (guestToken && refreshToken) {
        //Define client
        const _CLIENT = initializeApollo(guestToken)
        let { data, errors } = await _CLIENT.mutate({
          mutation: M_REFRESH_TOKEN,
          variables: { refresh_token: refreshToken },
          errorPolicy: "all",
        })
        if (data) {
          let { access_token, refresh_token, expires_in } = data.refreshToken
          nookies.set(ctx, `${process.env.ACCESS_TOKEN}`, access_token, {
            maxAge: 10 * 24 * 60 * 60,
            path: "/",
            sameSite: "none",
          })
          nookies.set(ctx, `${process.env.REFRESH_TOKEN}`, refresh_token, {
            maxAge: 25 * 24 * 60 * 60,
            path: "/",
            sameSite: "none",
          })
          return { token: accessToken, user_status: true }
        } else {
          return { user_status: false }
        }
      } else if (guestToken && !refreshToken) {
        return { token: guestToken, user_status: false }
      }
    } else {
      return { token: accessToken, user_status: true }
    }
  }
}
