import React, { useState } from "react"

//Main scss
import "assets/scss/main.scss"
import "react-datepicker/dist/react-datepicker.css"
// import "react-loading-skeleton/dist/skeleton.css"

//Utils
import { ApolloProvider } from "@apollo/client"
import { Web3Provider } from "@ethersproject/providers"
import { Web3ReactProvider } from "@web3-react/core"
import Cookies from "js-cookie"
import { useApollo } from "lib/apollo"
import { useStore } from "lib/redux"
import { useGeneralStore } from "lib/store"
import { getIntialData } from "lib/utils/get-initialdata"
import { TokenValidation } from "lib/utils/token-validation"
import App from "next/app"
import { Router, useRouter } from "next/router"
import TagManager from "react-gtm-module" //! For datalayer
import { Provider } from "react-redux"
import axios from "axios"

//Components
import FullLoadingCover from "components/global/full-loading-cover"
import ModalTncDwp from "components/global/modal-tnc-dwp"
import Toast from "components/global/toast/toast"

//!Loading at top screen
import nProgress from "nprogress"
nProgress.configure({ showSpinner: false })
Router.events.on("routeChangeStart", (url) => nProgress.start())
Router.events.on("routeChangeComplete", () => nProgress.done())
Router.events.on("routeChangeError", () => nProgress.done())

const getLibrary = (provider) => {
  return new Web3Provider(provider)
}

function MyApp({
  Component,
  pageProps,
  token,
  status,
  initialReduxState,
  // currency,
}) {
  //Utils **************************************************************************************//
  const routerNext = useRouter()
  const store = useStore(initialReduxState)
  const client = useApollo(pageProps?.initialApolloState, token)
  const [isError, setIsError] = useState(false)
  //Utils **************************************************************************************//

  //Functions **********************************************************************************//
  const [loadingState, setLoadingState] = React.useState(true)

  const handleGetInitialData = async () => {
    try {
      //VerifyToken
      let guest_token = Cookies.get(process.env.GUEST_TOKEN)
      if (!guest_token) {
        let { data: tokenData } = await axios({
          url: process.env.GET_TOKEN_URI,
          method: "POST",
          data: {
            grant_type: process.env.TOKEN_TYPE,
            client_id: process.env.PASSPORT_CLIENT_ID,
            client_secret: process.env.PASSPORT_CLIENT_SECRET,
          },
        })
        Cookies.set(process.env.GUEST_TOKEN, tokenData?.access_token, {
          expires: 7,
        })
        window.location.reload()
      } else {
        const { token, status } = await TokenValidation()
        let access_token = Cookies.get(process.env.ACCESS_TOKEN)
        let initialData = await getIntialData(
          token,
          access_token ? true : false,
          routerNext
          // currency
        )
        setTimeout(() => {
          setLoadingState(false)
        }, 1000)
      }
    } catch (error) {
      setLoadingState(false)
    }
  }

  //Functions **********************************************************************************//

  //React Operations **************************************************************************//
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      handleGetInitialData()
    }
  }, [])
  React.useEffect(() => {
    if (TagManager.initialize) {
      TagManager.initialize({
        gtmId: process.env.GOOGLE_TAG_MANAGER,
      })
    }
  }, [])
  //React Operations **************************************************************************//
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <>
          <ModalTncDwp />
          <Toast />
          {loadingState || !client ? (
            <>
              <FullLoadingCover show={loadingState} />
              <Component {...pageProps} />
            </>
          ) : (
            <ApolloProvider client={client}>
              <FullLoadingCover show={loadingState} />
              <Component {...pageProps} />
            </ApolloProvider>
          )}
        </>
      </Provider>
    </Web3ReactProvider>
  )
}

export default MyApp

MyApp.getInitialProps = async (appContext) => {
  let appProps = await App.getInitialProps(appContext)
  let { token } = await TokenValidation(appContext?.ctx)
  // let currency = await CheckGeolocation(appContext.ctx)
  return { ...appProps, token }
}
