import React, { useEffect, useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import { SET_TOAST, SET_TOAST_HEIGHT } from "lib/redux/type"

const Toast = () => {
  const dispatch = useDispatch()

  const { isToast, toastState, toastHeight } = useSelector(
    (state) => state.general
  )
  // const [toastHeight, setToastHeight] = useState(false)

  const handleClose = () =>
    dispatch({ type: SET_TOAST, payload: { show: false } })

  useEffect(() => {
    if (isToast)
      setTimeout(() => {
        dispatch({ type: SET_TOAST, payload: { show: false } })
      }, 4000)
  }, [isToast])

  useEffect(() => {
    const navTop = document.querySelector(".navbar-top")
    if (navTop) {
      dispatch({ type: SET_TOAST_HEIGHT, payload: true })
    } else {
      dispatch({ type: SET_TOAST_HEIGHT, payload: false })
    }
  }, [toastHeight])

  return (
    <>
      <div
        className={`toast-wrapper ${toastHeight ? "nav-toast" : ""} ${
          toastState.status
        } ${isToast ? "show" : "hide"}`}
      >
        <span>{toastState.message}</span>
        {/* <div className="close" onClick={handleClose}>
          <i className="ail ai-times" />
        </div> */}
      </div>
    </>
  )
}

export default Toast
